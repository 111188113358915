import {Component, OnDestroy, OnInit} from '@angular/core';
import {MainService} from '../../../services/main.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html'
})
export class NotFoundComponent implements OnInit, OnDestroy {

  constructor(public mainService: MainService) { }

  ngOnInit() {
    this.mainService.headerSubject.next(true);
  }
  ngOnDestroy(): void {
    this.mainService.headerSubject.next(false);
  }
}
