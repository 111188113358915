import {Component, OnDestroy, OnInit} from '@angular/core';
import {MainService} from '../../../services/main.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  public inner_header: boolean;
  public mobile_menu = false;
  constructor(public mainService: MainService) {
    this.mainService.header.subscribe((res: boolean) => this.inner_header = res);
  }

  ngOnInit() {
  }

  closeMenu(): void {
    this.mobile_menu = false;
    this.changeMobileMenu();
  }

  changeMobileMenu(): void {
    const queryS = document.querySelector('body').classList;
    const queryH = document.querySelector('header').classList;

    if (this.mobile_menu) {
      queryS.add('overflow');
      queryH.add('mobile-header');
    } else {
      queryS.remove('overflow');
      queryH.remove('mobile-header');
    }
  }


  ngOnDestroy(): void {
    document.querySelector('body').classList.remove('overflow');
    document.querySelector('header').classList.remove('mobile-header');
  }

}
