import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {FaqService} from './faq.service';
import {EMPTY, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResolveFaqService {

  constructor(private fs: FaqService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
    if (route.queryParams.hasOwnProperty('page')) {
        return this.getItems(() => this.fs.getQuestions(`?page=${route.queryParams['page']}`));
    } else {
      return this.getItems(() => this.fs.getQuestions(`?page=${1}`));
    }
  }

  private getItems(modeCallback) {
    return modeCallback().pipe(
      map(faqs => {
        if (faqs) {
          return of(faqs);
        } else {
          this.router.navigate(['/']);
          return EMPTY;
        }
      }), catchError((err, caught) => {
        if (err.status === 404) {
          this.router.navigate(['/faq'], {queryParams: {page: `${1}`}});
          return EMPTY;
        }
      })
    );
  }
}
