import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  headerSubject = new BehaviorSubject<Boolean>(false);
  header = this.headerSubject.asObservable();
  constructor() { }
}
