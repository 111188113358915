import { Component, OnInit } from '@angular/core';
import {FaqService} from '../faq/faq.service';
import {ResponseModel} from '../../../shared/models/response-model';
import {Faq} from '../faq/faq.model';
import {BlogService} from '../blog/blog.service';
import {BlogModel} from '../blog/blog.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  faqs: Array<Faq> = [];
  blogs: Array<BlogModel> = [];
  constructor(private fs: FaqService, private blogService: BlogService) {
    this.fs.getQuestions<ResponseModel<Faq>>('?page=1').subscribe(res => {
      this.faqs = res.body.results;
    });
    this.blogService.getPosts<ResponseModel<BlogModel>>('?page=1').subscribe(res => {
      if (res) {
        this.blogs = res.body.results.filter(i =>  res.body.results.indexOf(i) < 2);
      }
    });
  }

  ngOnInit() {
  }

}
