import {EllipsisDirective} from './ellipsis.directive';
import {NgModule} from '@angular/core';
import {PopupDirective} from './popup.directive';

@NgModule({
  declarations: [
    EllipsisDirective,
    PopupDirective
  ],
  exports: [
    EllipsisDirective,
    PopupDirective
  ]
})
export class DirectiveModule {
}
