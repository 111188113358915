import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BaseHttpService} from '../../../services/base-http-service.service';
import {ConcatModel} from './concat.model';

@Injectable({
  providedIn: 'root'
})
export class ConcatUsService extends BaseHttpService {
  private QUESTION_URL = '/contact-us';

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  send<T>(concat: ConcatModel): Observable<HttpResponse<T>> {
    return this.post(`${this.QUESTION_URL}`, concat);
  }
}
