import { NgModule } from '@angular/core';
import {Routes, RouterModule, NoPreloading} from '@angular/router';

import { HomeComponent } from './components/pages/home/home.component';
import {FeaturesComponent} from './components/pages/features/features.component';
import {FaqComponent} from './components/pages/faq/faq.component';
import {ContactUsComponent} from './components/pages/contact-us/contact-us.component';
import {NotFoundComponent} from './components/pages/not-found/not-found.component';
import {ResolveFaqService} from './components/pages/faq/resolve-faq.service';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'features',
    component: FeaturesComponent
  },
  {
    path: 'blog',
    loadChildren: './components/pages/blog/blog.module#BlogModule'
  },
  {
    path: 'faq',
    component: FaqComponent,
    resolve: {faqs: ResolveFaqService}
  },
  {
    path: 'contact-us',
    component: ContactUsComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
