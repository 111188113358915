import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FaqService} from './faq.service';
import {ResponseModel} from '../../../shared/models/response-model';
import {Faq} from './faq.model';
import {closePopup} from '../../../utils/header';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html'
})
export class FaqComponent implements OnInit {
  questions: Array<Faq> = [];
  page = 1;
  totalItems: number;
  limit = 10;
  popupAnswer: string;
  popupQuestion: string;

  constructor(private activatedRoute: ActivatedRoute, private fs: FaqService, private router: Router) { }

  ngOnInit() {
    this.activatedRoute.data
      .subscribe((data: { faqs: any }) => {
        this.initDataAfterResolve(data.faqs);
      }).unsubscribe();
  }

  isReadMoreHidden(elem: HTMLSpanElement, answer): boolean {
    return elem.innerHTML.length === answer.length;
  }

  initPopupText(index: number): void {
    this.popupAnswer = this.questions[index].answer;
    this.popupQuestion = this.questions[index].question;
  }

  changeQueryParams(page = 1): void {
      this.router.navigate(['/faq'], {queryParams: {page: `${page}`}});
  }

  initQueryParams(): void {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    if (queryParams.hasOwnProperty('page')) {
      this.page = +queryParams['page'];
      this.page = Math.ceil(this.totalItems / this.limit) < this.page ? 1 : this.page;
    }
    this.subscribeToRouterParams();
  }

  private initDataAfterResolve(data): void {
    if (data) {
      data.subscribe(res => {
        this.totalItems = res.body.count;
        this.questions = res.body.results;
        this.initQueryParams();
      });
    } else {
      this.questions = [];
      return;
    }
  }

  private subscribeToRouterParams(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      let page = 1;
      if (params.hasOwnProperty('page')) {
        page = +params.page === 0 ? 1 : +params.page;
        if (this.page !== page) {
          this.changePage(page);
        }
      }
    });
  }

  changePage(event): void {
      this.fs.getQuestions<ResponseModel<Faq>>(`?page=${event}`).subscribe(response => {
        this.initDataAfterPageChange(event, response);
      }, error => {
        if (error.status !== 401) {
          this.changePage(1);
        } else if (error.status === 0) {
          // this.authService.logOut();
        }
      });
  }

  private initDataAfterPageChange(page, response) {
    this.page = page;
    this.totalItems = response.body.count;
    this.questions = response.body.results;
  }

  public closePopup(): void {
    closePopup();
  }

}
