export const validationMessages = [
  {type: 'username_required', message: 'Please enter your Name.'},
  {type: 'username_letters', message: 'Name must contain only letters.'},
  {type: 'email_required', message: 'Please enter your email.'},
  {type: 'email_valid', message: 'Please enter a valid email.'},
  {type: 'phone_number_valid', message: 'Please enter a valid number.'},
  {type: 'message', message: 'The maximum length of a message is 500 characters'},
  {type: 'message_required', message: 'Please enter your message.'}
];

export const emailPattern: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const userNamePattern: RegExp = /^[a-zA-Z-' ]*$/;
export const phonePattern: RegExp = /\(?\+[0-9]{1,3}\)? ?-?[0-9]{1,3} ?-?[0-9]{3,5} ?-?[0-9]{4}( ?-?[0-9]{3})? ?(\w{1,10}\s?\d{1,6})?/;

