import {NgModule} from '@angular/core';
import {NgxPaginationModule} from 'ngx-pagination';
@NgModule({
  declarations: [],
  imports: [
    NgxPaginationModule,
  ],
  exports: [
    NgxPaginationModule,
  ],
  providers: []
})
export class SharedModule {
}
