import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BaseHttpService} from '../../../services/base-http-service.service';

@Injectable({
  providedIn: 'root'
})
export class FaqService extends BaseHttpService {
  private QUESTION_URL = '/get-faq-list';

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  getQuestions<T>(query: string): Observable<HttpResponse<T>> {
    return this.get(`${this.QUESTION_URL}${query}`);
  }
}
