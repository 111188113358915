import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {emailPattern, phonePattern, userNamePattern, validationMessages} from '../../../validation/validation-helper-model';
import {ConcatModel} from './concat.model';
import {ConcatUsService} from './concat-us.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html'
})
export class ContactUsComponent implements OnInit {
  form: FormGroup;
  errorMessages = validationMessages;
  submitted = false;
  constructor(private toastr: ToastrService,
              private fb: FormBuilder,
              private concatUsService: ConcatUsService,
              private router: Router) { }

  ngOnInit() {
    this.form = this.fb.group({
      username: [null,  Validators.compose([Validators.required, Validators.pattern(userNamePattern)])],
      email: [null,  Validators.compose([Validators.required, Validators.pattern(emailPattern)])],
      message: [null,  Validators.compose([Validators.required, Validators.maxLength(500)])],
      phone_number: [null],
    });
  }

  get f() { return this.form.controls; }

  errorMessage(key: string): string {
    return this.errorMessages.find(i => i.type === key).message;
  }


  submit(formValue) {
    this.submitted = true;
    if (this.form.valid) {
      const concatModel = new ConcatModel();
      concatModel.email = formValue['email'];
      concatModel.user_name = formValue['username'];
      concatModel.phone_number = formValue['phone_number']['number'];
      concatModel.message = formValue['message'];
      this.concatUsService.send<{message: string}>(concatModel).subscribe(
        res => {
          this.toastr.success(res.body.message);
          this.router.navigate(['/']);
          },
        error1 => {
          this.toastr.error('Sorry, something went wrong. Please try again.');
         }
      );
    }
  }

}
