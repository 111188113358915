import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/pages/home/home.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatExpansionModule} from '@angular/material/expansion';
import { OwlModule } from 'ngx-owl-carousel';
import { HeaderComponent } from './components/pages/header/header.component';
import { FeaturesComponent } from './components/pages/features/features.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { FooterComponent } from './components/pages/footer/footer.component';
import {HttpClientModule} from '@angular/common/http';
import {DirectiveModule} from './directives/directive.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {BsDropdownModule} from 'ngx-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import {SharedModule} from './shared/module/shared.module';
import {NgxPaginationModule} from 'ngx-pagination';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FeaturesComponent,
    FaqComponent,
    ContactUsComponent,
    NotFoundComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    DirectiveModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    HttpClientModule,
    OwlModule,
    NgxPaginationModule,
    BsDropdownModule.forRoot(),
    ToastrModule.forRoot(),
    NgxIntlTelInputModule,
	  SharedModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class AppModule { }
